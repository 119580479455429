import React, {useEffect} from "react";
import authReducer from "./AuthReducers";
import {createContext, useReducer} from "react";

const INITIAL_STATE = {
  user: JSON.parse(sessionStorage.getItem("user")) || null,
  isFetching: false,
  error: false
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(authReducer, INITIAL_STATE);

  useEffect(()=>{
    sessionStorage.setItem("user", JSON.stringify(state.user));
  },[state.user])

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
